import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from './utills/request'
import Vant from 'vant';
import 'vant/lib/index.css';
import 'amfe-flexible';
let appid = 'wx9b953ef216e0186a'
let appkey = '5879bb4fcb0b9c4609287f5602c28e70'
let wxRedirectUri = location.href
console.log(process.env)
if (process.env.NODE_ENV === 'development') {
  wxRedirectUri = 'https://18133zm677.51mypc.cn'

}


var code = getQueryString("code");
if (code != "" && code != null) { //微信回调页面传递的code，需要自己再拿到后台去校验获取用户数据

  localStorage.setItem('code',code)
} else {
  // 没有code再跳转一次，防止没有进行授权（OpenId），获取不到用户信息。
  location.href = "https://open.weixin.qq.com/connect/oauth2/authorize?appid=" + appid + "&redirect_uri=" + wxRedirectUri + "&response_type=code&scope=snsapi_userinfo&state=STATE#wechat_redirect";
}


//获取url的参数
function getQueryString(name) {
  var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)", "i");
  var r = window.location.search.substr(1).match(reg);
  if (r != null) return unescape(r[2]);
  return null;
}




Vue.prototype.$axios = axios
Vue.use(Vant);
Vue.config.productionTip = false



new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')