<template>
  <div class="vue-wrap">
    <div class="wrap-view">
      <router-view />
    </div>
    <!-- <van-tabbar v-model="active">

      <van-tabbar-item icon="records-o" to="/suggest">售后</van-tabbar-item>
      <van-tabbar-item icon="wap-home-o" to="/">开户</van-tabbar-item>
    </van-tabbar> -->
  </div>
</template>
<script>
export default {
  data() {
    return {
      active: 1,
    };
  },
};
</script>
<style lang="scss">
html,
body {
  width: 100%;
  height: 100%;


}
.vue-wrap {
  height: 100%;
  

  .wrap-view {
    height: calc(100%  - env(safe-area-inset-bottom));
    padding-bottom: constant(safe-area-inset-bottom);
    padding-bottom: env(safe-area-inset-bottom);
    overflow: scroll;
  }
}
</style>
