import Vue from 'vue'
import VueRouter from 'vue-router'
import axios from '../utills/request'


Vue.use(VueRouter)

const routes = [{
    path: '/',
    name: 'home',
    component: () => import( /* webpackChunkName: "about" */ '../views/home.vue')
  },
  {
    path: '/fire',
    name: 'fire',
    component: () => import( /* webpackChunkName: "about" */ '../views/fire.vue')
  },
  {
    path: '/wait',
    name: 'wait',
    component: () => import( /* webpackChunkName: "wait" */ '../views/wait.vue')
  },
  {
    path: '/suggest',
    name: 'suggest',
    component: () => import( /* webpackChunkName: "suggest" */ '../views/suggest.vue')
  },
  {
    path: '/result',
    name: 'result',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import( /* webpackChunkName: "result" */ '../views/result.vue')
  }
]

const router = new VueRouter({
  routes,
  mode: 'history',

})

async function getCode() {
  return new Promise(async (resolve, reject) => {
    let code = localStorage.getItem('code')
    if (!code) {
      return resolve()
    }
    let data = await axios.post('/wxapi/wxh5login', {
      code
    })
    let token = data.entity?.token
    localStorage.setItem('token', token);
    localStorage.setItem('code', '')
    resolve()
  })

}

router.beforeEach(async (to, from, next) => {


    await getCode().then(() => {
      next()
    })
  

});
export default router